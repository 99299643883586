.pages-title {
  color: var(--menu-color);
}

.search-form {
  position:relative;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 40px;
  border-radius: 40px;
  transform: translate(-50%, -50%);
  background: rgba(182, 174, 174, 0.664);
  transition: all 0.3s ease;
}
.filter-form {
  position:relative;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 40px;
  border-radius: 40px;
  transform: translate(-50%, -50%);
  background: rgba(182, 174, 174, 0.664);
  transition: all 0.3s ease;
}

.search-input {
  position: relative;
  width: 80%;
  height: 20px;
  top: 10px;
  left: 38px;
  background: none;
  color: #5a6674;
  border: none;
  appearance: none;
  outline: none;
}
.filter-input {
  position: relative;
  width: 70%;
  height: 20px;
  top: 10px;
  left: 38px;
  background: none;
  color: #5a6674;
  border: none;
  appearance: none;
  outline: none;
}

.search-button {
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  outline: none !important;
  cursor: pointer;
}

.svg {
  position: relative;
  top: 5px;
  left: 5px;
  height: 20px;
  width: 20px;
  fill: #5a6674;
  opacity: 0.6;
  transition: all 0.2s;
}

.icons{
  color: var(--menu-color);
  position:relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: max-content;
  cursor: pointer;
  font-size: 22px;
  padding-right: 10px;
}

.padding{ 
  padding-left: 10px;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .search-form {
    position:relative;
    top: 50%;
    left: 50%;
    width: 180px;
    height: 40px;
    border-radius: 40px;
    background: rgba(182, 174, 174, 0.664);
  }
  .filter-form {
    position:relative;
    top: 50%;
    left: 50%;
    width: 90px;
    height: 40px;
    border-radius: 40px;
    transform: translate(-50%, -50%);
    background: rgba(182, 174, 174, 0.664);
    transition: all 0.3s ease;
  }
}