.footer{
    font-size: 13px;
    margin-top: 0.5rem;
    padding: 2rem;
    padding-bottom: 0%;
    background-color: var(--app-color1);
    /* position:fixed; */
    /* position: static; */
    display: flex;
    width: 100%;
    /* height: 100%; */
    justify-content: space-between;
    color: var(--footer-color);
  }

  .inline{
    display: inline-flex;
  }

  .between{
    padding-inline-start: 2rem;
  }

  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .footer{
      padding-right: 2.5rem;
      padding-left: 0.5rem;
    }
  }