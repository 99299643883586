
/* DataTableDemo.css */

.datatable-filter-demo .p-paginator .p-paginator-current {
    margin-left: auto;
  }
  .datatable-filter-demo .p-progressbar {
    height: 0.5rem;
    background-color: #498bce;
  }
  .datatable-filter-demo .p-progressbar .p-progressbar-value {
    background-color: #607d8b;
  }
  .datatable-filter-demo .p-datepicker {
    min-width: 25rem;
  }
  .datatable-filter-demo .p-datepicker td {
    font-weight: 400;
  }
  .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }
  .datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
  }
  .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
  }
  .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
  }
  .datatable-filter-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
                 
  
  .customer-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }
  
  .customer-badge.status-qualified {
    background-color: #C8E6C9;
    color: #256029;
  }
  
  .customer-badge.status-unqualified {
    background-color: #FFCDD2;
    color: #C63737;
  }
  
  .customer-badge.status-negotiation {
    background-color: #FEEDAF;
    color: #8A5340;
  }
  
  .customer-badge.status-new {
    background-color: #B3E5FC;
    color: #23547B;
  }
  
  .customer-badge.status-renewal {
    background-color: #ECCFFF;
    color: #694382;
  }
  
  .customer-badge.status-proposal {
    background-color: #FFD8B2;
    color: #805B36;
  }
  
  .product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }
  
  .product-badge.status-instock {
    background: #C8E6C9;
    color: #256029;
  }
  
  .product-badge.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }
  
  .product-badge.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
  
  .order-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }
  
  .order-badge.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }
  
  .order-badge.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }
  
  .order-badge.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }
  
  .order-badge.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
  
  .image-text {
    vertical-align: middle;
    margin-left: .5rem;
  }
  
  .p-multiselect-representative-option {
    display: inline-block;
    vertical-align: middle;
  }
  
  .p-multiselect-representative-option img {
    vertical-align: middle;
    width: 24px;
  }
  
  .p-multiselect-representative-option span {
    margin-top: .125rem;
  }
  
  .p-column-filter {
    width: 100%;
  }
  
  .country-item {
    display: flex;
    align-items: center;
  }
  
  .country-item img.flag {
    width: 18px;
    margin-right: .5rem;
  }
  
  .flag {
    vertical-align: middle;
  }
  
  span.flag {
    width:44px;
    height:30px;
    display:inline-block;
  }
  
  img.flag {
    width:30px
  }
  .card{
    width: 770px;
  }

  /* .tableColor{
    color: #98b99b !important ;
  } */